import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  return (
    <>
      <div className="main-page">
        <nav id="navbar">
          <h1 className="logo">
            <Link to="/home" className="aaa">Job<span>Orbit</span></Link>
          </h1>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/jobs">Jobs</Link>
            </li>
            <li>
              <Link to="/internships">Internship</Link>
            </li>
            {/* <li>
              <Link to="/post-job">Post Job</Link>
            </li> */}
            {/* <li>
              <Link to="/saved-job">Saved Job</Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
