import React from 'react';
import './footer.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className='fcontainer'>
            <div className='social-icons'>
                <a href="https://www.linkedin.com/company/job-orbit" className="social-icon" target="_blank" rel="noopener noreferrer "><LinkedInIcon/></a>
                <a href="https://t.me/joborbit" className="social-icon" target="_blank" rel="noopener noreferrer "><TelegramIcon/></a>
            </div>
            <span className='copyright'>{currentYear} © Job Orbit. All rights reserved.</span>
        </div>
    );
}

export default Footer;
